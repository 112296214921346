<template>
    <div>
        <PageHeaderLayout>
            <div class="main-page-content">
                <el-row :gutter="10" class="mb8">
                    <el-col :span="22" style="text-align: center;font-size: 20px;font-weight: 800">
                        {{ searchCondition.year }}年{{ searchCondition.month }}月 提报刊例
                    </el-col>
                    <el-col :span="2" style="text-align: right">
                        <el-button class="fanhui-button" type="primary" size="mini" icon="iconfont icon-back"
                                   @click="backParentPage">返回</el-button>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="default-table">
                            <ApeTable ref="apeTable" :data="dataList" :columns="columns" :loading="loadingStatus"
                                      :pagingData="pagingData"
                                      @switchPaging="switchPaging" highlight-current-row border>
                                <el-table-column label="序号" align="center" width="80" slot="first-column">
                                  <template slot-scope=" { row, column, $index }">
                                    {{ offset + $index + 1 }}
                                  </template>
                                </el-table-column>
                                <el-table-column
                                        slot="first-column"
                                        width="80"
                                        align="center"
                                        label="Drag">
                                    <template slot-scope="scope">
                                        <el-tooltip effect="dark" content="拖动排序" placement="top-start">
                <span class="drag-handle" :data-id="scope.row.id" :data-parent_id="scope.row.parent_id"
                      :data-depth="scope.row.depth"><i class="el-icon-rank"></i></span>
                                        </el-tooltip>
                                    </template>
                                </el-table-column>


                                <el-table-column label="刊例标题" header-align="center" align="left" width="300"
                                                 slot="second-column"
                                                 :show-overflow-tooltip="true">
                                    <template slot-scope="scope">
                                        <el-link type="primary" @click="jumpTo(scope.row)">{{ scope.row.title || '/'
                                            }}
                                        </el-link>
                                    </template>
                                </el-table-column>
                              <el-table-column label="有效期" align="center" width="200" slot="second-column">
                                <template slot-scope="scope">
                                  <span>{{ scope.row.validity_date }}</span>
                                  ~
                                  <span>{{ scope.row.expiry_date }}</span>
                                </template>
                              </el-table-column>
                              <el-table-column label="操作" width="100" align="center" fixed="right">
                                <template slot-scope="{row,$index}">
                                  <el-button size="mini" type="text" icon="el-icon-delete"
                                             @click="handleDelete(row)" :ref="`refExportLink_${$index}`"
                                             v-if="userPermissions.indexOf('plc_delete')>-1">删除
                                  </el-button>

                                </template>
                              </el-table-column>
                            </ApeTable>
                        </div>
                    </el-col>
                </el-row>

            </div>
        </PageHeaderLayout>
    </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import Sortable from 'sortablejs'

export default {
  name: 'PlcList',
  components: { ExportBtn, ApeTable, PageHeaderLayout },
  data() {
    return {
      loadingStatus: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      searchCondition: { year: null, month: null },
      orderSort: { year: 'desc', month: 'desc', id: 'asc' },//默认排序规则
      dataList: [
        // {
        //   year: '2022',
        //   month: '04',
        //   title: '2022年5月缇苏小红书刊例',
        //   items_count: 288,
        //   status_alias: '提报中',
        //   period_validity_alias: '2022-05-01 ～ 2022-05-30',
        //   deadline_alias: '2022-04-25',
        //   created_at: '2022-01-01',
        //   updated_at: '2022-01-02'
        // },
        // {
        //   year: '2022',
        //   month: '03',
        //   title: '2022年4月缇苏抖音刊例',
        //   items_count: 288,
          //   status_alias: '提报中',
          //   period_validity_alias: '2022-05-01 ～ 2022-05-30',
          //   deadline_alias: '2022-04-25',
          //   created_at: '2022-01-01',
          //   updated_at: '2022-01-02'
          // },

        ],
        columns: [
          // {title: '提报时间', value: 'date_alias', width: 120},
          // {title: '刊例标题', value: 'title', width: 200},
          { title: '提报数量', value: 'items_count', width: 100 },
          // {title: '状态', value: 'status_alias', width: 100},
          // {title: '有效期', value: 'period_validity', width: 120},
          { title: '提报截止时间', value: 'deadline', width: 120 },
          { title: '创建时间', value: 'created_at', width: 120 },
          { title: '最后修改时间', value: 'updated_at', width: 120 }
        ],
        // 分页信息
        pagingData: {
          is_show: true,
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0
          // offset: 0,
        },
        // 分页的offset,序号列使用
        offset: 0,
        offer_info: ''
        ,
        video_info: ''
      }
    },
    computed: {
      ...mapGetters(['userPermissions', 'userInfo'])
    },
    methods: {
      addBtn() {
        // this.$notify.info('新建刊例')
        this.$router.push({ name: 'plc_create2' })
      },
      switchPaging() {
        this.initList()
      },
      jumpTo(row) {
        //调整
        // this.$router.push({ name: 'plc_detail', params: { id: row.id } })
        this.$router.push({ name: 'plc_detail_item', params: { id: row.id } })
      },
      // 初始化列表
      async initList(type) {
        this.loadingStatus = true
        let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
        let searchCondition = this.handleSearchCondition()
        // Object.assign(searchCondition, pagingInfo)
        Object.assign(searchCondition, pagingInfo, { order: this.orderSort })
        this.dataList = []
        let { list, pages } = await this.$api.getPublicationList(searchCondition)
        this.$nextTick(() => {
          this.dataList = list
        })
        this.pagingData.total = pages.total
        this.offset = pages.offset
        this.loadingStatus = false
      },
      // 处理搜索条件
      handleSearchCondition() {
        let condition = {}

        if (this.searchCondition.year) {
          condition.year = this.searchCondition.year
        }
        if (this.searchCondition.month) {
          condition.month = this.searchCondition.month
        }

        // if (this.searchCondition.platform_code) {
        //   condition.platform_code = this.searchCondition.platform_code
        // }
        // if (this.searchCondition.title) {
        //   condition.title = this.searchCondition.title
        // }
        // if (this.searchCondition.artist_id) {
        //   condition.artist_id = this.searchCondition.artist_id
        // }
        return condition
      },
      backParentPage() {
        this.$router.push({ name: 'plc' })
        // this.$router.go(-1)
      },
      handleDelete(row) {
        this.$confirm('此操作将永久删除该月刊例, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.$api.deletePublicationInfo(row.id)
          await this.initList()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
      //导出表格
      async exportData(row, index) {
        try {
          let response = await this.$api.exportPublication(row.id)
          let name = `${row.title}`
          this.$refs[`refExportLink_${index}`].exportSaveXlsx(response, name)
        } catch (e) {
          this.$message.warning('导出异常，请联系管理员')
        }
      },


      // 拖拽排序数据提交，请求接口
      async dragSortSubmit(orderIds) {
        let t = this
        // let ids = this.$refs['treeTable'].getExpandIds()
        let info = await this.$api.orderPublication(orderIds)
        if (info == 'ok') {
          // let { list } = await this.$api.getCategoryList()
          t.initList()

          // this.categoryList = []
          // this.$nextTick(() => {
          //   this.categoryList = list
          //   this.$nextTick(() => {
          //     this.$refs['treeTable'].initTableExpand(ids)
          //   })
          // })

        }
        // console.log(orderIds)
        this.$message.success('保存成功!')
      },
      // 表格拖拽排序，同层级移动有效果
      dragSort() {
        const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
        // console.log(el)
        this.sortable = Sortable.create(el, {
          handle: '.drag-handle',
          setData: function(dataTransfer) {
            dataTransfer.setData('Text', '')
            // to avoid Firefox bug
            // Detail see : https://github.com/RubaXa/Sortable/issues/1012
          },
          onEnd: evt => {
            let oldId = evt.item.querySelectorAll('.drag-handle')[0].dataset.id
            let Ids = []
            let orderIds = []
            let newList = []
            let tmp = el.querySelectorAll('.drag-handle')
            for (let i = 0, len = tmp.length; i < len; i++) {
              newList[tmp[i].dataset.id] = { 'parent_id': tmp[i].dataset.parent_id, 'depth': tmp[i].dataset.depth }
              Ids.push(tmp[i].dataset.id)
            }
            // 处理组合实际请求后台数据
            for (let i = 0, len = Ids.length; i < len; i++) {
              if (newList[oldId].parent_id == newList[Ids[i]].parent_id && newList[oldId].depth == newList[Ids[i]].depth) {
                orderIds.push(Ids[i])
              }
            }
            this.dragSortSubmit(orderIds)
          }
        })
      }

    },
    mounted() {
      this.initList()

    },
    created() {
      this.searchCondition.year = this.$route.params && this.$route.params.year
      this.searchCondition.month = this.$route.params && this.$route.params.month

      this.$nextTick(() => {
        this.dragSort()
      })
    }
  }
</script>

<style scoped lang="scss">
    .export-btn {
        padding: 7px 8px;
        /*margin-top: 20px;*/
        /*margin-right: 5px;*/
        /*margin-bottom: 1px;*/
        font-size: 12px !important;
        font-stretch: 100%
        /*font-weight: 600;*/
    }

    .drag-handle {
        font-size: 24px;
        cursor: pointer;
    }

</style>
